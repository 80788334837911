import styled, { keyframes } from "styled-components";
import Particles from "react-tsparticles";

export const NiaContent = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 900px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 3%;
  overflow-x: hidden;
  background-color: var(--color-title-primary);
  overflow: hidden;
  @media (max-width: 565px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
  }
`;

export const NiaParticles = styled.div``;

export const ParticlesNia = styled(Particles)`
  .tsparticlesclass {
    position: relative !important;
  }
  #tsparticle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red;
  }
`;

export const NiaHeader = styled.div`
  max-width: 50%;
  height: 60vh;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 565px) {
    max-width: 90%;
    height: auto;
  }
`;

export const NiaTitle = styled.h4`
  width: 100%;
  margin-bottom: 5%;
  text-align: ;eft;
  padding-left: 0;
  font: 700 34px Roboto;
  color: var(--color-secondary);
  z-index: 3;
`;

export const NiaSubtitle = styled.h5`
  width: 100%;
  margin-bottom: 5%;
  text-align: left;
  padding-left: 0;
  font: 700 24px Roboto;
  color: var(--color-secondary);
  z-index: 3;
`;

export const NiaText = styled.p`
  color: white;
  z-index: 3;
  font-size: 18px;
  @media (max-width: 565px) {
    text-align: center;
    font-size: 16px;
  }
`;

export const upDown = keyframes`
  0%, 100% {
    padding-bottom: 0;
  }
  50% {
    padding-bottom: 10px;
  }
`;

export const NiaAnimation = styled.img`
  width: 700px;
  heigth: 400px;
  margin-right: -300px;
  z-index: 3;
  animation: ${upDown} 1.5s linear infinite;
  @media (max-width: 565px) {
    width: 350px;
    margin-top: 20px;
    animation: none;
    margin-right: -155px;
  }
`;

export const NiaBox = styled.div`
  z-index: 3;
  div {
    width: 50px;
    margin-top: 50px;
    z-index: 3;
    box-shadow: 10px 10px 30px 10px black;
    margin: 0 auto;
  }
`;
