import styled from "styled-components";

export const CardSolution = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 540px;
  align-items: center;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) -3px 10px 11px, inset rgb(255 255 255 / 9%) 1px 0;
  background: #ffffff;
  padding: 4%;
  @media (max-width: 565px) {
    width: 100%;
    max-height: auto;
    margin: 2% auto;
    min-width: 330px;
    height: 580px;
  }
`;

export const CardImg = styled.img`
  width: 280px;
  padding-top: 40px;
  margin: 0 auto;
  padding-bottom: 10px;
  @media (max-width: 565px) {
    max-width: 120px;
  }
`;

export const Text = styled.div`
  height: auto;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  padding: 0 0 8%;
  max-width: 530px;
`;
