import React, { useEffect } from "react";
import * as S from "./styles";

const NavigationSide = () => {
  useEffect(() => {
    const tabSlide1 = document.querySelectorAll(".js-scroll");
    const tabHeader = document.querySelectorAll(".nav-link");
    const slideLink1 = document.querySelectorAll(".js-sld");
    const windowMetade = window.innerHeight * 0.6;
    const activeSlide = (index) => {
      var t = "st-" + index;
      slideLink1.forEach((a) => {
        a.classList.remove("slide-ativo");
      });
      tabHeader.forEach((h) => {
        h.classList.remove("active");
      });
      const item = document.getElementById(t);
      item.classList.add("slide-ativo");
      switch (index) {
        case 0:
          document.getElementById("inicio-link").classList.add("active");
          break;
        case 1:
          document.getElementById("sobre-link").classList.add("active");
          break;
        case 3:
          document.getElementById("solucoes-link").classList.add("active");
          break;
        case 9:
          document.getElementById("contato-link").classList.add("active");
          break;
        default:
          break;
      }
    };

    const checkSec = () => {
      tabSlide1.forEach((itemAtivo, index) => {
        const sectionTop = itemAtivo.getBoundingClientRect().top;
        const isSectionVisible = sectionTop - windowMetade < 0;

        if (isSectionVisible) {
          if (index === 0) {
            document.getElementById("navbar").classList.remove("bg-blue");
            document.getElementById("logoNavbar").style.display = "none";
          } else if (index !== 0) {
            document.getElementById("navbar").classList.add("bg-blue");
            document.getElementById("logoNavbar").style.display = "block";
          }
          activeSlide(index);
        }
      });
    };
    checkSec();

    window.addEventListener("scroll", checkSec);
  }, []);

  const Circle = ({ idx, section, text }) => {
    return (
      <S.Circle
        id={`st-${idx}`}
        className="circulo-slide js-sld"
        href={`#${section}`}
      >
        <span className="lateral-texto">{text}</span>
      </S.Circle>
    );
  };

  return (
    <S.NavigationSide>
      <Circle idx={0} section={"inicio-section"} text={"Início"} />
      <Circle idx={1} section={"sobre-section"} text={"Sobre"} />
      <Circle idx={2} section={"numeros-section"} text={"Numeros"} />
      <Circle idx={3} section={"solucoes-section"} text={"Soluções"} />
      <Circle idx={4} section={"app-section"} text={"Aplicativo"} />
      <Circle idx={5} section={"nia-section"} text={"N.I.A"} />
      <Circle idx={6} section={"comments-section"} text={"Declarações"} />
      <Circle idx={7} section={"clientes-section"} text={"Clientes"} />
      <Circle idx={8} section={"parceiros-section"} text={"Parceiros"} />
      <Circle idx={9} section={"contato-section"} text={"Contato"} />
    </S.NavigationSide>
  );
};

export default NavigationSide;
