import styled from "styled-components";

export const NavigationSide = styled.div`
  width: 20px;
  height: 100vh;
  position: fixed;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  .slide-ativo {
    opacity: 1;
    width: 15px;
    height: 15px;
  }
  @media (max-width: 565px) {
    display: none;
  }
`;

export const Circle = styled.a`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: black;
  opacity: 0.4;
  color: white;
  margin-bottom: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  span {
    display: none;
  }

  :hover {
    justify-content: flex-start;
    width: 100px;
    height: 20px;
    background: black;
    border-radius: 5%;
    opacity: 1;
    text-decoration: none;
    color: white;
    font-size: 12px;
    text-align: left;
    span {
      display: block;
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }
`;
