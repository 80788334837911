import styled from "styled-components";

export const ClientSection = styled.section`
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-background);
  align-items: center;
  position: relative;
  @media (max-width: 565px) {
    padding: 30px;
  }
`;

export const ClientContent = styled.div`
  width: 100%;
  padding: 40px 0px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas: ". . . . . .";
  @media (max-width: 565px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content;
    grid-template-areas: ". . . ";
    gap: 20px;
    padding: 10px;
  }
  a {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ClienttHeader = styled.div`
  width: 100%;
`;

export const ClientTitle = styled.h4`
  border-left: 4px solid var(--color-secondary);
  padding-left: 1%;
  background: var(--color-background);
  margin-top: 0;
  margin-bottom: 1rem;
  font: 700 34px Roboto;
  color: var(--color-title-primary);
  @media (max-width: 565px) {
    font: 700 24px Roboto;
    padding-left: 2%;
  }
`;
