import styled from "styled-components";

export const CardAbout = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) -3px 10px 11px, inset rgb(255 255 255 / 9%) 1px 0;
  background: #ffffff;
  margin: 20px 10px;
  margin-bottom: 10px;
  @media (max-width: 565px) {
    max-height: auto;
    margin: 0 auto !important;
  }
`;

export const CardAboutTitle = styled.p`
  color: var(--color-primary) !important;
  font-weight: 700;
  font-size: 16px;
`;

export const CardAboutContent = styled.div`
  font-size: 16px;
  text-align: center;
`;
