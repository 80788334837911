import React from "react";
import * as S from "./styles";
import Zoom from "react-reveal/Zoom";

const CardClient = ({ image, link, text }) => {
  return (
    <Zoom>
      <S.CardClient>
        <a href={link} target="_blank" rel="noreferrer">
          <S.ClientImg src={image} alt={text} />
        </a>
      </S.CardClient>
    </Zoom>
  );
};

export default CardClient;
