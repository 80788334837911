import styled, { keyframes } from "styled-components";
import brand from "../../assets/images/icons/whatsapp.png";

const tremer = keyframes`
  0%{
    transform: scale(0.8);
  }
  50%{
    transform: scale(1)
  }
  100%{
    transform: scale(0.8);
  }
 
  
`;

export const WhatsButton = styled.a`
  width: 70px;
  position: fixed;
  bottom: 20px;
  border-radius: 100%;
  right: 10px;
  height: 70px;
  background-image: url(${brand});
  background-size: contain;
  z-index: 5;
  animation: ${tremer} 2s infinite;
  box-shadow: rgb(0 0 0 / 20%) -3px 10px 11px, inset rgb(255 255 255 / 9%) 1px 0;
  @media (max-width: 565px) {
    width: 60px;
    height: 60px;
  }
`;
