import styled from "styled-components";
import fundo from "../../assets/images/imgExtras/fundoRelevo.jpg";

export const SolutionsSection = styled.section`
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-image: url(${fundo});
  background-repeat: repeat;
  z-index: 1;
  @media (max-width: 565px) {
    padding: 30px;
  }
`;

export const SolutionsHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SolutionsTitle = styled.p`
  width: 30%;
  border-left: 4px solid var(--color-secondary);
  padding-left: 1%;
  background: var(--color-background);
  margin-top: 0;
  margin-bottom: 1rem;
  font: 700 34px Roboto;
  color: var(--color-primary);
  @media (max-width: 565px) {
    font: 700 24px Roboto;
    padding-left: 2%;
    width: 90%;
  }
`;

export const SolutionsContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
  gap: 40px 40px;
  grid-template-areas:
    ". ."
    ". .";
  @media (max-width: 565px) {
      grid-template-columns: 1fr;
      grid-template-rows: max-content max-content max-content max-content;
      grid-auto-flow: row;
      gap: 10px 40px;
      grid-template-areas:
        "."
        "."
        "."
        ".";
    }
    
  }
`;
