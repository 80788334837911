import Divider from "../../components/Divider";
import Device from "../../components/Device/Device";
import * as S from "./styles";

import appStore from "../../assets/images/icons/apple.png";
import googlePlay from "../../assets/images/icons/google-play.png";

import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const Application = () => {
  const [t] = useTranslation();
  return (
    <S.AppSection id="app-section" className="js-scroll">
      <S.AppContent>{!isMobile && <Device />}</S.AppContent>
      <S.AppHeader>
        <S.AppTitle>{t("solucoes.titulo-prospec")}</S.AppTitle>
        <h5>{t("solucoes.apptexto")}</h5>
        {isMobile && <Device />}
        <S.StoreButtons>
          <S.StoreButton
            href="https://apps.apple.com/br/app/prospec/id1583292392"
            target="_blank"
          >
            <img src={appStore} alt="app store" />
            {t("solucoes.botãoAppstore")}
          </S.StoreButton>
          <S.StoreButton
            href="https://play.google.com/store/apps/details?id=com.appprospec"
            target="_blank"
          >
            <img src={googlePlay} style={{ width: 40 }} alt="google play" />

            {t("solucoes.botãoGooglePlay")}
          </S.StoreButton>
        </S.StoreButtons>
      </S.AppHeader>
      <Divider />
    </S.AppSection>
  );
};
export default Application;
