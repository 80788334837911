/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as S from "./styles";
import React from "react";
import logo from "../../assets/images/logos/globo.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [t, i18n] = useTranslation();

  function handleChangeLang(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <S.Nav
      className="navbar navbar-expand-lg navbar-dark bg-blue fixed-top"
      id="navbar"
    >
      <a className="navbar-brand" href="/">
        <S.ImageLogo id="logoNavbar" className="planet-logo" src={logo} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav">
          <li className="nav-item" id="inicio">
            <a className="nav-link" href="#inicio-section" id="inicio-link">
              {t("menu.inicio")} <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item" id="sobre">
            <a className="nav-link" href="#sobre-section" id="sobre-link">
              {t("menu.quemSomos")} <span className="sr-only">(current)</span>
            </a>
          </li>

          <li className="nav-item" id="serviços">
            <a className="nav-link" href="#solucoes-section" id="solucoes-link">
              {t("menu.servicos")} <span className="sr-only">(current)</span>
            </a>
          </li>

          <li className="nav-item" id="contatos">
            <a className="nav-link" href="#contato-section" id="contato-link">
              {t("menu.contatos")} <span className="sr-only">(current)</span>
            </a>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("menu.acessos")}
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                className="dropdown-item"
                href="http://indireto.smgeo.com.br/login"
                target="_blank"
              >
                SMGeo Indireto
              </a>
              <a
                className="dropdown-item"
                href="http://direto.smgeo.com.br/"
                target="_blank"
              >
                SMGeo Direto
              </a>
              <a
                className="dropdown-item"
                href="http://consulta.smgeo.com.br/"
                target="_blank"
              >
                SMGeo Consulta
              </a>
              <a className="dropdown-item disabled" href="#" target="_blank">
                Sirflor
              </a>
            </div>
          </li>
          <li className="nav-item-translation">
            <S.ButtonTranslate
              className="dropdown-item"
              active={i18n.language === "pt"}
              onClick={() => handleChangeLang("pt")}
            >
              PT-BR
            </S.ButtonTranslate>
          </li>
          <li className="nav-item-translation">
            <S.ButtonTranslate
              className="dropdown-item"
              active={i18n.language === "en"}
              onClick={() => handleChangeLang("en")}
            >
              EN
            </S.ButtonTranslate>
          </li>
          <li className="nav-item-translation">
            <S.ButtonTranslate
              className="dropdown-item"
              active={i18n.language === "sp"}
              onClick={() => handleChangeLang("sp")}
            >
              ES
            </S.ButtonTranslate>
          </li>
          <li className="nav-item dropdown">
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              {/*<a className="dropdown-item">PT-BR</a>
							<a className="dropdown-item">EN-US</a>
							<a className="dropdown-item">PT-PT</a>*/}
            </div>
          </li>

          <div></div>
        </ul>
      </div>
    </S.Nav>
  );
};

export default Header;
