import * as S from "./syles";
import niagif from "../../assets/gif/niaanimada.gif";
import { particlesConfig } from "./utils";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const Nia = () => {
  const [t] = useTranslation();
  return (
    <S.NiaContent id="nia-content" className=" js-scroll">
      <S.NiaParticles>
        <S.ParticlesNia id="tsparticles" options={particlesConfig} />
      </S.NiaParticles>
      <S.NiaHeader>
        <S.NiaTitle>{t("solucoes.titulo-nia")}</S.NiaTitle>
        <S.NiaSubtitle>{t("solucoes.subtitulo-nia")}</S.NiaSubtitle>
        <Fade left>
          <S.NiaText>{t("solucoes.sobre-nia2")}</S.NiaText>
        </Fade>
      </S.NiaHeader>
      <S.NiaBox>
        <S.NiaAnimation src={niagif} />
        <div></div>
      </S.NiaBox>
    </S.NiaContent>
  );
};
export default Nia;
