/* eslint-disable react/jsx-no-target-blank */
import CardClient from "../../components/CardClient/CardClient";
import conception from "../../assets/images/newclients/concepcion.png";
import alvo from "../../assets/images/newclients/alvo.png";
import durli from "../../assets/images/newclients/durli.png";
import estancia from "../../assets/images/newclients/estancia.png";
import alianca from "../../assets/images/newclients/alicanca.png";
import altamira from "../../assets/images/newclients/alttamira.png";
import exporta from "../../assets/images/newclients/agroexport.png";
import coper from "../../assets/images/newclients/cooperfrigo.png";
import friato from "../../assets/images/newclients/frialto.png";
import friboi from "../../assets/images/newclients/friboi.png";
import fribev from "../../assets/images/newclients/fribev.png";
import frigol from "../../assets/images/newclients/frigol.png";
import frisacre from "../../assets/images/newclients/frisacre.png";
import mafri from "../../assets/images/newclients/mafrinorte.png";
import master from "../../assets/images/newclients/masterboi.png";
import mercurio from "../../assets/images/newclients/mercurio.png";
import gpa from "../../assets/images/newclients/gpa.png";
import natura from "../../assets/images/newclients/naturafrig.png";
import minerva from "../../assets/images/newclients/minerva.png";
import plena from "../../assets/images/newclients/plena.png";
import rio from "../../assets/images/newclients/riomaria.png";
import sao from "../../assets/images/newclients/saofrancisco.png";
import prima from "../../assets/images/newclients/prima.png";
import sbc from "../../assets/images/newclients/sbc.png";
import vancouros from "../../assets/images/newclients/vancouros.png";
import volpato from "../../assets/images/newclients/volpato.png";
import fribal from "../../assets/images/newclients/fribal.png";
import valencio from "../../assets/images/newclients/valencio.png";
import independencia from "../../assets/images/newclients/independencia.png";
import pantanal from "../../assets/images/newclients/pantanal.png";
import frigon from "../../assets/images/newclients/frigon.png";
import boibras from "../../assets/images/newclients/boibras.png";
import frigobom from "../../assets/images/newclients/frigobom.png";
import barramansa from "../../assets/images/newclients/barramansa.png";
import viposa from "../../assets/images/newclients/viposa.png";
import proboi from "../../assets/images/newclients/proboi.png";
import real from "../../assets/images/newclients/real.png";
import * as S from "./styles";
import { useTranslation } from "react-i18next";

const Clients = () => {
  const [t] = useTranslation();
  return (
    <S.ClientSection id="clientes-section" className="js-scroll">
      <S.ClienttHeader>
        <S.ClientTitle>{t("clientes.titulo")}</S.ClientTitle>
      </S.ClienttHeader>
      <S.ClientContent>
        <CardClient
          link={"http://www.agroexport.agr.br/pt/empresa.php"}
          image={exporta}
        />
        <CardClient image={alianca} />

        <CardClient
          link={"#"}
          image={altamira}
          text="Abatedouro Independência"
        />
        <CardClient image={alvo} link="" />
        <CardClient image={conception} link="" />
        <CardClient link={"https://cooperfrigu.com.br/"} image={coper} />
      </S.ClientContent>
      <S.ClientContent>
        <CardClient link={"https://durlicouros.com.br/"} image={durli} />
        <CardClient image={estancia} />
        <CardClient link={"http://www.frialto.com.br/"} image={friato} />
        <CardClient image={fribev} />
        <CardClient link={""} image={friboi} />
        <CardClient link={"https://frigol.com.br/pt/"} image={frigol} />
      </S.ClientContent>

      <S.ClientContent>
        <CardClient image={gpa} />
        <CardClient link={"http://www.mafrinorte.com.br/"} image={mafri} />
        <CardClient link={"https://www.masterboi.com.br/"} image={master} />
        <CardClient
          link={"http://www.mercurioalimentos.com.br/"}
          image={mercurio}
        />
        <CardClient
          link={"https://www.minervafoods.com/marcas-brasil/"}
          image={minerva}
        />
        <CardClient image={natura} />
      </S.ClientContent>
      <S.ClientContent>
        <CardClient image={frisacre} />
        <CardClient link={"https://www.plenaalimentos.com.br/"} image={plena} />
        <CardClient link={"https://www.primeasialeather.com/"} image={prima} />

        <CardClient
          link={"http://www.frigorificoriomaria.com.br/"}
          image={rio}
        />
        <CardClient image={sao} />
        <CardClient image={sbc} />
      </S.ClientContent>
      <S.ClientContent>
        <CardClient image={vancouros} />
        <CardClient image={volpato} />
        <CardClient image={fribal} />
        <CardClient image={valencio} />
        <CardClient image={independencia} />
        <CardClient image={viposa} />
      </S.ClientContent>
      <S.ClientContent>
        <CardClient image={proboi} />
        <CardClient image={pantanal} />
        <CardClient image={barramansa} />
        <CardClient image={frigon} />
        <CardClient image={boibras} />
        <CardClient image={frigobom} />
      </S.ClientContent>
      <S.ClientContent>
        <CardClient image={real} />
      </S.ClientContent>
    </S.ClientSection>
  );
};
export default Clients;
