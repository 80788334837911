import styled from "styled-components";

export const Nav = styled.nav`
  z-index: 4;
  width: 100%;
  min-height: 60px;
  .bg-blue {
    background-color: #0e5996 !important;
    img {
      display: block;
    }
  }

  .nav-link {
    margin: 0 14.5px;
    font-size: 16px;
  }
  .nav-item-translation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
  }
  .dropdown-item {
    font-size: 16px;
  }
`;

export const ImageLogo = styled.img`
  width: 2.3vw;
  display: none;
  @media (max-width: 565px) {
    width: 40px;
  }
`;

export const ButtonTranslate = styled.button.attrs((props) => ({
  type: "button",
}))`
  color: ${({ active }) => (active ? "#2a855b" : "#666")}!important;
`;
