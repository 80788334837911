import styled from "styled-components";
import depoiments from "../../assets/images/imgExtras/depoimentoSatelite.png";

export const CommentsSection = styled.section`
  width: 100%;
  height: 800px;
  background-image: url(${depoiments});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2;
  polygon {
    fill: white;
  }
  .react-multi-carousel-list {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 565px) {
    height: 600px;
    .react-multi-carousel-list {
      height: 500px;
    }
  }
`;

export const CommentsCarrosel = styled.div`
  position: relative;
`;

export const CarroselInner = styled.div``;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  h2 {
    max-width: 80%;
    font-size: 24px;
    color: white;
    text-align: center;
    font-family: "Roboto";
  }
  p {
    color: white;
    font-size: 16px;
    padding-top: 10px;
  }
  img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: var(--color-primary);
    margin: 10px auto;
  }

  @media (max-width: 565px) {
    h2 {
      font-size: 12px;
      max-width: 60%;
    }
    p {
      text-align: center;
      max-width: 60%;
      font-size: 12px;
    }
    img {
      width: 80px;
      height: 80px;
    }
  }
`;
