import * as S from "./styles";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

import CardAbout from "../../components/CardAbout";

const About = () => {
  const [t] = useTranslation();

  return (
    <S.AboutSection id="sobre-section" className="js-scroll">
      <S.SobreContent>
        <S.AboutHeader>
          <S.AboutTitle>{t("sobre.titulo")}</S.AboutTitle>
        </S.AboutHeader>
        <S.AboutContentText>
          <S.AboutCardText>
            <p>{t("sobre.texto-niceplanet")}</p>
          </S.AboutCardText>
        </S.AboutContentText>
        <S.AboutContentCards>
          <S.NavAbout>
            <Fade left>
              <CardAbout
                top
                icon="https://cdn.lordicon.com/gqzfzudq.json"
                title={t("sobre.sobre.titulo-card1")}
                content={t("sobre.sobre.texto-card1")}
              />
              <CardAbout
                icon="https://cdn.lordicon.com/zzcjjxew.json"
                title={t("sobre.sobre.titulo-card4")}
                content={t("sobre.sobre.texto-card4")}
              />
            </Fade>
          </S.NavAbout>
          <S.NavAbout2>
            <Fade right>
              <CardAbout
                top
                icon="https://cdn.lordicon.com/xxtkutpo.json"
                title={t("sobre.sobre.titulo-card3")}
                content={t("sobre.sobre.texto-card3")}
              />
              <CardAbout
                icon="https://cdn.lordicon.com/nocovwne.json"
                title={t("sobre.sobre.titulo-card2")}
                content={t("sobre.sobre.texto-card2")}
              />
            </Fade>
          </S.NavAbout2>
        </S.AboutContentCards>
      </S.SobreContent>
    </S.AboutSection>
  );
};

export default About;
