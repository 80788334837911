import React from "react";

const Divider = () => {
  return (
    <div
      className="custom-shape-divider-bottom-1630625702 divider"
      style={{ zIndex: 3 }}
    >
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M1200 0L0 0 892.25 114.72 1200 0z"
          className="shape-fill"
        ></path>
      </svg>
    </div>
  );
};

export default Divider;
