import * as S from "./styles";
import daniel from "../../assets/images/clientsreport/danielfreire.jpg";
import fauto from "../../assets/images/clientsreport/faustoneto.jpg";
import mauro from "../../assets/images/clientsreport/maurolucio.jpg";
import ivens from "../../assets/images/clientsreport/ivensdomingos.jpg";
import mauricio from "../../assets/images/clientsreport/mauriciofraga.jpg";
import pedro from "../../assets/images/clientsreport/pedroburnier.jpg";
import carlos from "../../assets/images/clientsreport/carloseduardo.jpg";
import tamara from "../../assets/images/clientsreport/tamara.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Comments = () => {
  const slides = [
    {
      comment:
        "As ferramentas desenvolvidas pela Niceplanet , como as plataformas de monitoramento SMGeo direto, o SMGeo Consulta, o aplicativo Prospec são iniciativas que integram o compromisso Minerva Foods com a Sustentabilidade. Através dessa parceria firmada há mais de 10 anos, atingimos resultados pioneiros no monitoramento socioambiental de 100% de nossos fornecedores diretos no Brasil e Paraguai, além de avançarmos para a Colômbia, Argentina e Uruguai. A robustez de nossos sistemas posiciona a MINERVA FOODS com os melhores resultados entre os principais players nas mais completas auditorias de terceiro setor realizadas anualmente.",
      description: "TAMARA LOPES - GERENTE EXECUTIVA DE SUSTENTABILIDADE",
      img: tamara,
    },
    {
      comment:
        "A NICEPLANET®  tem oferecido o sistema de monitoramento socioambiental mais seguro do mercado, o que faz com que tenhamos confiança em apostar mais alto no mercado mundial de carnes, pois temos um produto à altura das exigências internacionais. Além disso, o dia a dia da compra de gado de um frigorífico é um processo que exige agilidade e confiança no procedimento de análise das fazendas fornecedoras e conseguimos realizar de maneira rápida e eficiente com suporte que a empresa oferece. Tudo isso com a tranquilidade e certeza de 100% de compliance nas principais auditorias do setor.",
      description:
        "DANIEL FREIRE - DIRETOR DA MERCÚRIO ALIMENTOS E PRESIDENTE DO SINDICARNE-PA",
      img: daniel,
    },
    {
      comment:
        "Quando a FRIGOL adotou estratégias para se alinhar às necessidades de sustentabilidade na originação de matéria prima exigidas pelo mercado, a NICEPLANET® nos deu garantia total no monitoramento socioambiental, e passamos a ter resultados superiores  e imediatos nas principais auditorias realizadas pelos órgãos federais, o que nos faz ter tranquilidade na nossa escolha. Além disso, a tecnologia desenvolvida nos permite compartilhar as mais completas informações de rastreabilidade aos nossos consumidores.",
      description:
        "CARLOS EDUARDO CORRÊA - DIRETOR ADMINISTRATIVO E SUSTENTABILIDADE FRIGOL",
      img: carlos,
    },
    {
      comment:
        "Os sistemas e procedimentos desenvolvidos pela NICEPLANET® nos permite adquirir animais com tranquilidade e a certeza de estarmos cumprindo 100% de todos os acordos de legalidade e socioambientais exigidos pelo mercado.",
      description: "FAUSTO NETO - DIRETOR DE OPERAÇÕES AGROEXPORT TRADING",
      img: fauto,
    },
    {
      comment:
        "A parceria com a NICEPLANET® e a utilização do app SmGeo Prospec me permite realizar a análise dos meus fornecedores de gado para recria e engorda (animais indiretos dos frigoríficos), mantendo a minha cadeia produtiva totalmente conectada com os mais modernos princípios de legalidade e sustentabilidade que tenho implantado na Fazenda Marupiara e transmitido no âmbito do agronegócio, que é a Produção com alta rentabilidade e responsabilidade socioambiental.",
      description: "MAURO LÚCIO COSTA - FAZENDA MARUPIARA",
      img: mauro,
    },
    {
      comment:
        "Os sistemas e procedimentos desenvolvidos pela NICEPLANET® nos entregam o que existe de mais moderno e eficiente em termos de rastreabilidade e sustentabilidade dos couros das fazendas produtoras dos animais até as nossas unidades beneficiamento, de forma a cumprir todas as exigências dos principais mercados nacionais e internacionais.",
      description: "IVENS DOMINGOS  - GERENTE DE SUSTENTABILIDADE DURLI COUROS",
      img: ivens,
    },
    {
      comment:
        "A NICEPLANET® é uma empresa responsável e parceira que conta com grande prestígio entre os produtores. Os sistemas e procedimentos que desenvolvem ajudam a melhorar a segurança jurídica de todos os elos das cadeias produtivas do agronegócio, melhorando a imagem dos produtores e indústrias perante um mercado cada vez mais exigente.",
      description: "MAURÍCIO FRAGA - Presidente da Acripará",
      img: mauricio,
    },
    {
      comment:
        "A NICEPLANET® é uma empresa de geotecnologia comprometida em desenvolver soluções para os desafios que sempre lhes apresentamos, trabalhando em conjunto com Amigos da Terra – Amazônia Brasileira de forma transparente e colaborativa. Seu compromisso com a sustentabilidade da cadeia produtiva da pecuária é demonstrado pelo bom desempenho que os frigoríficos, que utilizam seus sistemas, tem apresentando recorrentemente nas auditorias exigidas pelo MPF para o atendimento ao Termo de Ajustamento de Conduta da Carne.",
      description: " PEDRO BURNIER - AMIGOS DA TERRA - AMAZÔNIA BRASILEIRA",
      img: pedro,
    },
  ];

  return (
    // Deixar o nome e função como brancos.
    <S.CommentsSection id="comments-section" className="js-scroll">
      <Carousel responsive={responsive}>
        {slides.map((slide, index) => (
          <S.Slide key={index}>
            {slide.img && <img src={slide.img} alt="" />}
            <h2>"{slide.comment}"</h2>
            <p>{slide.description}</p>
          </S.Slide>
        ))}
      </Carousel>
    </S.CommentsSection>
  );
};
export default Comments;
