import React from "react";
import * as S from "./styles";
import MapChart from "./MapChart";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Map = () => {
  const [t] = useTranslation();
  return (
    <>
      <S.MapSection id="numeros-section" className="js-scroll">
        <S.MapHeader>
          <S.MapTitle>{t("mapa.titulo")}</S.MapTitle>
        </S.MapHeader>
        <S.MapShadow />
        <S.MapContent>
          <MapChart />
        </S.MapContent>
        <S.NumbersContent>
          <Fade>
            <S.Numbers>
              <S.NumbersInfo>
                <S.NumebersTitle>
                  <TrackVisibility>
                    {({ isVisible }) =>
                      isVisible ? (
                        <div>
                          <p></p>
                          <CountUp end={5} duration={0.5} />
                          <p>{t("mapa.paises")}</p>
                        </div>
                      ) : (
                        0
                      )
                    }
                  </TrackVisibility>
                </S.NumebersTitle>
                <S.NumbersText>
                  <p>{t("mapa.paisesSubtitulo")}</p>{" "}
                </S.NumbersText>
              </S.NumbersInfo>
              <S.NumbersInfo>
                <S.NumebersTitle>
                  <TrackVisibility>
                    {({ isVisible }) =>
                      isVisible ? (
                        <>
                          <p>+</p>
                          <CountUp end={187} duration={2} />
                          <p>{t("mapa.companhias")}</p>
                        </>
                      ) : (
                        0
                      )
                    }
                  </TrackVisibility>
                </S.NumebersTitle>
                <S.NumbersText>{t("mapa.companhias-texto")}</S.NumbersText>
              </S.NumbersInfo>
              <S.NumbersInfo>
                <S.NumebersTitle>
                  <TrackVisibility>
                    {({ isVisible }) =>
                      isVisible ? (
                        <>
                          <p>+</p>
                          <CountUp end={79} duration={2} />{" "}
                          <p>Mil {t("mapa.propriedades")}</p>
                        </>
                      ) : (
                        0
                      )
                    }
                  </TrackVisibility>
                </S.NumebersTitle>
                <S.NumbersText>{t("mapa.propriedades-texto")}</S.NumbersText>
              </S.NumbersInfo>
              <S.NumbersInfo>
                <S.NumebersTitle>
                  <TrackVisibility>
                    {({ isVisible }) =>
                      isVisible ? (
                        <>
                          <p>+</p>
                          <CountUp end={85} duration={1.5} />{" "}
                          <p>{t("mapa.hectare")}</p>
                        </>
                      ) : (
                        0
                      )
                    }
                  </TrackVisibility>
                </S.NumebersTitle>
                <S.NumbersText>{t("mapa.hectare-texto")}</S.NumbersText>
              </S.NumbersInfo>
            </S.Numbers>
          </Fade>
        </S.NumbersContent>
      </S.MapSection>
    </>
  );
};

export default Map;
