import "./styles/App.css";

// Import Components
import Header from "./components/Header/Header";
import NavigationSide from "./components/NavigationSide/index";
import Video from "./components/Video";
import Map from "./pages/Map/Map";

import Nia from "./pages/Nia/Nia";
import About from "./pages/About/About";
import Clients from "./pages/Clients/Clients";
import Contacts from "./pages/Contacts/Contacts";
import Solutions from "./pages/Solutions/Solutions";
import Application from "./pages/Application/Application";
import Comments from "./pages/Comments/Comments";
import Partner from "./pages/Partner/Partner";
import WhatsButton from "./components/WhatsButton/WhatsButton";
const App = () => {
  return (
    <div className="js-page" id="page-landing">
      <Header />
      <NavigationSide />
      <Video />
      <About />
      <Map />
      <Solutions />
      <Application />
      <Nia />
      <Comments />
      <Clients />
      <Partner />
      <Contacts />
      <WhatsButton />
    </div>
  );
};

export default App;
