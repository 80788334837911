import styled from "styled-components";

export const CardContact = styled.div`
  min-height: 400px;
  min-width: 520px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 20px;
  background-color: white;
  @media (max-width: 565px) {
    width: 100%;
    min-width: auto;
    max-width: 350px;
  }
`;

export const CardTitle = styled.h4`
  font: 700 24px Roboto;
  color: var(--color-secondary);
  text-transform: uppercase;
  @media (max-width: 565px) {
    font: 700 24px Roboto;
    margin: 0 auto;
  }
`;

export const CardSubTitle = styled.h6`
  opacity: 0.5;
  margin-bottom: 1.5%;
  text-align: left;
  font: 700 1.4rem Roboto;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @media (max-width: 565px) {
    margin: 0 auto;
  }
`;

export const CardContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  @media (max-width: 565px) {
    width: 100%;
    gap: 10px;
  }
`;

export const CardInfos = styled.div``;

export const CardAtribute = styled.div`
  display: flex;
  flex-direction: row;
  span {
    margin-left: 10%;
    font-weight: 700;
  }
  @media (max-width: 565px) {
    span {
      margin-left: 5%;
    }
    width: 100%;
    justify-content: space-start;
    aling-items: start;
    span {
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

export const QRCode = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 565px) {
    display: none;
  }
`;
