import * as S from "./styles";

const CardContact = ({ name, func, image, phone, social, email }) => {
  return (
    <S.CardContact>
      <S.CardTitle>{name}</S.CardTitle>
      <S.CardSubTitle>{func}</S.CardSubTitle>
      <S.CardContent>
        <S.QRCode src={image} />
        <S.CardInfos>
          <S.CardAtribute>
            <span>
              <i className="fas fa-phone-alt"></i>
            </span>

            <span>{phone}</span>
          </S.CardAtribute>
          <S.CardAtribute>
            <span>
              <i className="fab fa-skype"></i>
            </span>

            <span>{social}</span>
          </S.CardAtribute>
          <S.CardAtribute>
            <span>
              <i className="fas fa-at"></i>
            </span>
            <span>{email}</span>
          </S.CardAtribute>
        </S.CardInfos>
      </S.CardContent>
    </S.CardContact>
  );
};

export default CardContact;
