import styled from "styled-components";

export const AboutSection = styled.section`
  width: 100%;
  padding: 80px 40px;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 576px) {
    height: auto;
    padding: 30px;
  }
  z-index: 1;
`;

export const SobreContent = styled.div`
  width: 100%;
`;

export const AboutHeader = styled.div``;

export const AboutTitle = styled.p`
  border-left: 4px solid var(--color-secondary);
  padding-left: 1%;
  background: var(--color-background);
  margin-bottom: 1rem;
  font: 700 34px Roboto;
  color: var(--color-primary);
  @media (max-width: 565px) {
    font: 700 24px Roboto;
    padding-left: 2%;
  }
`;

export const AboutContentText = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  float: left;
  justify-content: left;
  flex-direction: column;
  @media (max-width: 565px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const AboutCardText = styled.div`
  width: 156%;
  margin-left: 55%;
  display: flex;
  align-items: left;
  background-color: var(--color-background-tertiary);
  justify-content: left;
  flex-direction: column;
  p {
    width: 50%;
    height: auto;
    padding: 5% 7%;
    text-align: left;
    font-size: 16px;
    color: var(--color-text-primar);
  }
  @media (max-width: 565px) {
    margin: 0 auto;
    width: 100%;
    p {
      width: 100%;
      text-align: center;
    }
  }
`;

export const AboutContentCards = styled.div`
  width: 48%;
  height: auto;
  margin-right: 2%;
  display: flex;
  float: right;
  justify-content: right;
  flex-direction: row;
  margin-top: -12 0px;
  @media (max-width: 565px) {
    width: 100%;
    margin-top: 0px;
    flex-direction: column;
    margin-right: 0px;
  }
`;

export const NavAbout = styled.div`
  width: 100%;
  padding: 0 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 449px;
  @media (max-width: 565px) {
    flex-direction: column;
    margin: 140px auto;
    max-height: auto;
    gap: 15px;
  }
`;

export const NavAbout2 = styled.div`
  width: 100%;
  padding: 0 1%;
  margin-top: -10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 565px) {
    flex-direction: column;
    margin: 0px auto;
    max-height: auto;
    gap: 15px;
    margin-top: 0px;
  }
`;
