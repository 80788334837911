import styled from "styled-components";
import fundo from "../../assets/images/imgExtras/footerback.PNG";

export const ContactSection = styled.section`
  position: relative;
  background-color: #95c47f;
  background: rgb(255, 255, 255);
  background: linear-gradient(to bottom, white 20%, #95c47f 100%, #95c47f 100%);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
`;

export const ContactHeader = styled.div`
  @media (max-width: 565px) {
    padding: 1%;
  }
`;

export const ContactTitle = styled.h4`
  text-align: center;
  background: var(--color-background);
  margin: 5% auto;
  font: 700 34px Roboto;
  color: var(--color-title-primary);
  @media (max-width: 565px) {
    margin-top: 0px;
    margin-bottom: 30px;
    font: 700 24px Roboto;
  }
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: max-content;
  gap: 2%;
  @media (max-width: 565px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 786px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  z-index: 2;
`;

export const ContactBox = styled.div`
  position: absolute;
  height: 400px;
  width: 100%;
  background-image: url(${fundo});
  bottom: 0;
  left: 0;
  z-index: 0;
`;

export const ContactFooter = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  img {
    margin-top: 150px;
    width: 140px;
  }
  span {
    text-align: center;
    font-size: 12px;
    color: white;
    font-family: Roboto;
    font-weight: 400;
    opacity: 0.6;
  }
`;
