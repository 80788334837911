import styled from "styled-components";

export const AppSection = styled.section`
  position: relative;
  width: 100%;
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  .divider {
    path {
      fill: var(--color-detalhes-azul-escuro);
    }
  }
  @media (max-width: 565px) {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0px;
  }
  :hover {
    .phone.view_1 {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      box-shadow: 0px 3px 0 rgb(0, 0, 0), 0px 4px 0 rgb(0, 0, 0),
        0px 5px 0 rgb(0, 0, 0), 0px 7px 0 rgb(0, 0, 0),
        0px 10px 20px rgb(24, 24, 24);
    }
  }
`;

export const AppHeader = styled.div`
  max-width: 60%;
  padding: 60px;
  h5 {
    color: #222;
    font-size: 16px;
    text-align: center;
    padding: 20px;
  }
  @media (max-width: 565px) {
    flex: 1;
    max-width: 100%;
    text-align: center;
  }
`;

export const AppTitle = styled.p`
  font: 700 34px Roboto;
  color: var(--color-secondary);
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  @media (max-width: 565px) {
    font: 700 24px Roboto;
  }
`;

export const AppSubtitle = styled.p`
  color: white;
  text-align: center;
  padding: 2%;
`;

export const AppContent = styled.div`
  flex: 1;
  @media (max-width: 565px) {
    height: auto;
  }
`;

export const StoreButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 565px) {
    flex-direction: column;
    height: 200px;
    flex: 1;
    padding-bottom: 200px;
  }
`;

export const StoreButton = styled.a`
  background-color: black;
  width: 180px;
  border-radius: 8px;
  margin: 20px;
  padding: 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
  font-weight: bold;
  font: 700 16px Roboto;
  img {
    width: 46px;
    margin-bottom: 4px;
    margin-right: 8px;
    margin-left: 4px;
  }
  :hover {
    text-decoration: none;
  }
  @media (max-width: 565px) {
    width: auto;
    margin: 0px;
  }
`;
