import React from "react";
import * as S from "./styles";

const CardAbout = ({ icon, title, content, top }) => {
  return (
    <S.CardAbout>
      <lord-icon
        src={icon}
        trigger="loop"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: 74, height: 80 }}
      />
      <S.CardAboutTitle>{title}</S.CardAboutTitle>
      <S.CardAboutContent className="texto-icones">
        <p>{content}</p>
      </S.CardAboutContent>
    </S.CardAbout>
  );
};

export default CardAbout;
