import "./style.css";
import appScren from "../../assets/gif/app-telas.mp4";

const Device = () => {
  return (
    <>
      <div id="wrapper">
        <div className="phone view_1" id="phone_1">
          <video src={appScren} autoPlay loop muted></video>
        </div>
      </div>
    </>
  );
};

export default Device;
