import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import {} from "react-motion";
const geoUrl = "https://unpkg.com/world-atlas@1.1.4/world/110m.json";

const MapChart = () => {
  return (
    <ComposableMap
      width={800}
      height={800}
      projection="geoOrthographic"
      projectionConfig={{ scale: 400, rotate: [100, 0, 10] }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => {
            if (
              geo.id === "076" ||
              geo.id === "170" ||
              geo.id === "600" ||
              geo.id === "032"
            ) {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#43ad4b"
                  stroke="#FFF"
                  style={{
                    default: {
                      fill: "#43ad4b",
                      outline: "none",
                    },
                  }}
                />
              );
            }
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
                style={{
                  default: {
                    fill: "#EAEAEC",
                    outline: "none",
                  },
                  hover: {
                    fill: "#222",
                    outline: "none",
                  },
                  pressed: {
                    fill: "#E42",
                    outline: "none",
                  },
                }}
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

export default MapChart;
