import React from "react";
import video from "../../assets/images/videos/videoniceplanet.mp4";
import logoNiceplanet from "../../assets/images/logos/nicehorizontal.svg";
import Divider from "../Divider";
import { isMobile } from "react-device-detect";

const Video = () => {
  return (
    <>
      <section id="inicio-section" className="logo-container js-scroll">
        <video id="video" src={video} autoPlay={true} loop muted></video>
        <div id="img-padrao"></div>
        <img
          style={{ fill: "#FFF" }}
          id="logo"
          src={logoNiceplanet}
          alt="NicePlanet Geotecnologia"
        ></img>
        {!isMobile && <Divider />}
      </section>
    </>
  );
};

export default Video;
