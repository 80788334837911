import styled from "styled-components";

export const MapSection = styled.section`
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: center;
  position: relative;
  background: linear-gradient(
    to top,
    rgb(230 229 229) 6%,
    rgba(255, 255, 255, 1) 64%
  );
  overflow: hidden;
  z-index: 1;
  @media (max-width: 565px) {
    min-height: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const MapHeader = styled.section`
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 6% 0% 6% 25%;
  width: 80%;
  @media (max-width: 565px) {
    padding: 20px;
    margin-top: 20px;
    position: relative;
  }
`;

export const MapTitle = styled.p`
  font-size: 3rem;
  letter-spacing: 2px;
  font: 700 34px Roboto;
  color: var(--color-title-primary);
  margin-top: 10%;
  margin-bottom: 1rem @media (max-width: 565px) {
    font-size: 2.5rem;
  }
`;

export const MapShadow = styled.div`
  width: 53%;
  background-color: transparent;
  -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  position: absolute;
  float: right;
  max-height: 905px;
  right: 79%;
  -webkit-box-shadow: 39px 0px 26px 0px rgb(50 50 50 / 61%);
  -moz-box-shadow: 39px 0px 26px 0px rgba(50, 50, 50, 0.61);
  box-shadow: 149px -9px 36px 0px rgb(133 133 133 / 48%);
  @media (max-width: 565px) {
    display: none;
    width: 0px;
  }
`;

export const MapContent = styled.div`
  position: absolute;
  background-color: #031537;
  width: 800px;
  height: 800px;
  top: 5%;
  float: left;
  left: -500px;
  -webkit-box-shadow: 39px 0px 26px 0px rgb(50 50 50 / 61%);
  -moz-box-shadow: 39px 0px 26px 0px rgba(50, 50, 50, 0.61);
  box-shadow: 80px -9px 36px 0px rgb(133 133 133 / 48%);
  border-radius: 100%;
  @media (max-width: 565px) {
    display: none;
    width: 0px;
  }
  pointer-events: none;
`;

export const MapCanva = styled.canvas``;

export const NumbersContent = styled.div`
  position: absolute;
  height: 100vh;
  margin-left: 30%;
  top: 18%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 974px;
  @media (max-width: 565px) {
    margin-left: auto;
    height: auto;
    margin-top: 0px;
    position: relative;
    top: 150px;
  }
`;

export const Numbers = styled.div`
  width: 55vw;
  height: auto;
  max-height: 70vh;
  @media (max-width: 565px) {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const NumbersInfo = styled.div`
  width: 50vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  @media (max-width: 565px) {
    width: 90%;
  }
`;

export const NumebersTitle = styled.p`
  width: 100%;
  margin: 0;
  text-align: left;
  color: var(--color-secondary);
  font: 700 45px Roboto;
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  p {
    margin-left: 20px;
  }
  @media (max-width: 565px) {
    width: auto;
    font-size: 34px;
    text-align: center;
  }
`;

export const NumbersText = styled.p`
  width: 100%;
  font-size: 18px;
`;

export const Number = styled.span`
  margin-right: 15px;
  font: 700 16px Roboto;
  color: var(--color-title-primary);
`;
