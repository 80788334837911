/* eslint-disable react/jsx-no-target-blank */
import * as S from "./styles";

import acripara from "../../assets/images/parceiros/acripara.svg";
import amigos from "../../assets/images/parceiros/amigosDaTerra.svg";
import ecotrace from "../../assets/images/parceiros/ecotrace.svg";
import fazenda from "../../assets/images/parceiros/FAZENDA-MARUPIARA.jpg";
import gpts from "../../assets/images/parceiros/gtps.svg";
import safe from "../../assets/images/parceiros/safeTrace.svg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Partner = () => {
  const slides1 = [
    {
      description: "parceiros.acripara",
      image: acripara,
    },
    {
      description: "parceiros.amigosTerra",
      image: amigos,
    },
    {
      description: "parceiros.ecotrace",
      image: ecotrace,
    },
    {
      description: "parceiros.maripuara",
      image: fazenda,
    },
    {
      description: "parceiros.gtps",
      image: gpts,
    },
    {
      description: "parceiros.safeTrace",
      image: safe,
    },
  ];

  const [t] = useTranslation();
  return (
    <S.PartnerSection id="paceiros-section" className="js-scroll">
      <S.PartnerContent>
        <S.PartnertHeader>
          <S.PartnerTitle>{t("parceiros.titulo")}</S.PartnerTitle>
        </S.PartnertHeader>
        <Carousel responsive={responsive}>
          {slides1.map((item, idx) => (
            <Fade key={idx + 10}>
              <S.Slide>
                <div>
                  <img src={item.image} alt={item.description} />
                  <h2>{t(`${item.description}`)}</h2>
                </div>
              </S.Slide>
            </Fade>
          ))}
        </Carousel>
      </S.PartnerContent>
    </S.PartnerSection>
  );
};
export default Partner;
