import CardContact from "../../components/CardContact/CardContact";
import jordan from "../../assets/images/QRCode/jordan_qrcode.jpeg";
import edinei from "../../assets/images/QRCode/edinei_qrcode.jpeg";
import logoNiceplanet from "../../assets/images/logos/nicehorizontal.svg";
import { useTranslation } from "react-i18next";

import * as S from "./styles";
const Contacts = () => {
  const { t } = useTranslation();

  return (
    <S.ContactSection id="contato-section" className="js-scroll">
      <S.ContactHeader>
        <S.ContactTitle>{t("contatos.titulo")}</S.ContactTitle>
      </S.ContactHeader>
      <S.ContactContent>
        <CardContact
          name="Jordan T. Carvalho"
          func={"Co-fundador / CEO"}
          image={jordan}
          phone={"(94) 99241-0205"}
          social={"jordan.suldopara"}
          email={"jordan@niceplanet.com.br"}
        />
        <CardContact
          name={"Edinei E. Menegetti"}
          func={"Co-fundador / CEO"}
          image={edinei}
          phone={"(94) 99141-4095"}
          social={"edinei.estevanio.menegetti"}
          email={"edinei@niceplanet.com.br"}
        />
      </S.ContactContent>
      <S.ContactBox />
      <S.ContactFooter>
        <img src={logoNiceplanet} alt="niceplanet" />
        <span>&copy; Copyright 2009-{new Date().getFullYear()}</span>
      </S.ContactFooter>
    </S.ContactSection>
  );
};
export default Contacts;
