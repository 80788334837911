import React from "react";
import * as S from "./styles";
import Fade from "react-reveal/Fade";

const CardSolution = ({ content, image, imageText }) => {
  return (
    <>
      <Fade left>
        <S.CardSolution>
          <S.CardImg src={image} alt={imageText} />
          <S.Text>{content}</S.Text>
        </S.CardSolution>
      </Fade>
    </>
  );
};

export default CardSolution;
