import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "../translations/en/translation.json";
import pt from "../translations/pt/translation.json";
import sp from "../translations/sp/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      pt: { translation: pt },
      sp: { translation: sp },
    },
    fallbackLng: "pt",
    interpolation: { escapeValue: true },
  });
export default i18n;
