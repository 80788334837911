import styled from "styled-components";

export const CardClient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ClientImg = styled.img`
  max-width: 8vw;
  max-height: 10vh;
  filter: none;
  -webkit-filter: grayscale(100);
  opacity: 0.5;
  :hover {
    cursor: pointer;
    filter: none;
    -webkit-filter: grayscale(0);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    opacity: 1;
  }
  @media (max-width: 565px) {
    max-width: 50%;
    opacity: 1;
    -webkit-filter: grayscale(0);
  }
`;
