import styled from "styled-components";

export const PartnerSection = styled.section`
  width: 100%;
  position: relative;
  background-color: rgb(233 233 233);
  padding-bottom: 80px;
  padding: 40px;
  z-index: 2;
  .slider {
    width: 100%;
    height: 400px;
  }
  .slider a.previousButton {
    left: 0px;
  }
  .slider a.nextButton {
    right: 0px;
  }
  polygon {
    fill: white;
  }
  @media (max-width: 565px) {
    padding: 30px;
    .slider a.previousButton {
      left: -10px;
    }
    .slider a.nextButton {
      right: -10px;
    }
  }
`;

export const PartnerContent = styled.div``;

export const PartnertHeader = styled.div``;

export const PartnerTitle = styled.h4`
  border-left: 4px solid var(--color-secondary);
  padding-left: 1%;
  margin-top: 0;
  margin-bottom: 40px;
  font: 700 34px Roboto;
  color: var(--color-title-primary);
  @media (max-width: 565px) {
    margin-bottom: 20px;
    font: 700 24px Roboto;
    padding-left: 2%;
  }
  .react-multi-carousel-list {
    width: 100%;
    height: 100%;
  }
`;

export const Slide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  div {
    box-shadow: rgb(0 0 0 / 20%) -3px 10px 11px,
      inset rgb(255 255 255 / 9%) 1px 0;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    height: 350px;
    text-align: center;
    max-width: 360px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h2 {
    max-width: 80%;
    font-size: 1.6rem;
    color: #222;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
  }
  p {
    color: #222;
    font-size: 2rem;
    padding-top: 10px;
  }
  img {
    width: 180px;
    height: 100px;
  }

  @media (max-width: 565px) {
    div {
      width: 80%;
      margin: 10px auto;
    }
    h2 {
      font-size: 1.3rem;
      max-width: 70%;
    }
    p {
      font-size: 1rem;
    }
  }
`;

export const SlideCard = styled.div``;
