import CardSolution from "../../components/CardSolution/CardSolution";

import * as S from "./styles";

// images
import smGeo from "../../assets/images/logos/smgeoIndireto.svg";
import smGeoDireto from "../../assets/images/logos/smgeoDireto.svg";
import smGeoConsulta from "../../assets/images/logos/smgeoConsulta.svg";
import sirFlor from "../../assets/images/logos/sirflor.svg";

import { useTranslation } from "react-i18next";

const Solutions = () => {
  const [t] = useTranslation();
  return (
    <S.SolutionsSection id="solucoes-section" className="js-scroll">
      <S.SolutionsHeader>
        <S.SolutionsTitle>{t("solucoes.titulo")}</S.SolutionsTitle>
      </S.SolutionsHeader>
      <S.SolutionsContent>
        {/* SMGeo indireto */}
        <CardSolution
          image={smGeo}
          imageText="SMGeo Indireto"
          content={t("solucoes.smgeoIndireto")}
        />

        {/* SMGeo direto */}
        <CardSolution image={smGeoDireto} content={t("solucoes.smgeoDireto")} />
        <CardSolution
          image={smGeoConsulta}
          content={t("solucoes.smgeoConsulta")}
        />

        {/*  Sirflor */}
        <CardSolution image={sirFlor} content={t("solucoes.sirflor")} />
      </S.SolutionsContent>
    </S.SolutionsSection>
  );
};

export default Solutions;
